import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

import { GeneralContext } from '../../../context/general';
import { reducer, generateInitialState } from '../../../utils/reducer';
import { useQueryParams } from '../../../hooks/queryParams';

import { Pagination } from '../../Pagination';

const takeDefault = 30;

export function GroupList() {
  const [query] = useQueryParams();
  const { url } = useRouteMatch();
  const { api, notificationActions } = React.useContext(GeneralContext);
  const [state, dispatchState] = React.useReducer(
    reducer,
    generateInitialState({ items: [], count: 0 })
  );
  const take = query.take ? Number(query.take) : takeDefault;
  const skip = query.skip ? Number(query.skip) : 0;

  function fetchGroupList() {
    dispatchState({ type: 'start' });

    const queryParams = new URLSearchParams();
    if (take) queryParams.set('take', String(take));
    if (skip) queryParams.set('skip', String(skip));
    if (query.region) queryParams.set('region', query.region);
    if (query.search) queryParams.set('search', query.search);
    if (query.all !== 'false') queryParams.set('all', 'true');

    api(`/admin/group?${queryParams.toString()}`)
      .then((data) => dispatchState({ type: 'success', data }))
      .catch((err) => dispatchState({ type: 'fail', error: console.log(err) }));
  }

  React.useEffect(() => {
    fetchGroupList();
  }, [take, skip, query.region, query.search, query.all]);

  const deleteHandler = (id) => {
    api(
      `/admin/group/${id}`,
      {
        method: 'DELETE',
      },
      null
    )
      .then(() => {
        fetchGroupList();
      })
      .catch((err) => {
        notificationActions.addNotification(err, { timeout: Infinity });
      });
  };

  return (
    <>
      <NavLink to={`${url}/edit`} className="button button-outline">
        Add group
      </NavLink>
      {state.loaded ? (
        <table>
          <thead>
            <tr>
              <th>id</th>
              <th>name</th>
              <th>region</th>
              <th>miners</th>
              <th align="right" style={{ paddingRight: 32 }}>
                actions
              </th>
            </tr>
          </thead>
          <tbody>
            {state.data.items.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.region.name}</td>
                <td>
                  <ol>
                    {item.miners.map((miner, idx) => (
                      <li key={idx}>{miner.id}</li>
                    ))}
                  </ol>
                </td>
                <td align="right">
                  <NavLink
                    className="button button-clear"
                    to={`${url}/edit/${item.id}`}
                  >
                    Edit group
                  </NavLink>
                  <button
                    className="button button-clear"
                    type="button"
                    onClick={() => deleteHandler(item.id)}
                  >
                    Delete group
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        'loading...'
      )}
      <Pagination skip={skip} take={take} total={state.data.count} />
    </>
  );
}
