import React from 'react';

import s from './s.module.css';

export const Notification = ({ n, onClose }) => {
  React.useEffect(() => {
    if (n.timeout && n.timeout !== Infinity) {
      setTimeout(onClose, n.timeout, n.id);
    }
  }, [n.timeout]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={s.notification}>
      {n.message}
      <button className={s.close} onClick={() => onClose(n.id)} type="button">
        <svg viewBox="0 0 10 10">
          <path
            d="M9.95 1.464L6.414 5 9.95 8.536 8.536 9.95 5 6.414 1.464 9.95.05 8.536 3.586 5 .05 1.464 1.464.05 5 3.586 8.536.05z"
            fill="currentColor"
            fillRule="evenodd"
          />
        </svg>
      </button>
    </div>
  );
};
