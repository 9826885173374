import React from 'react';
import { GeneralContext } from '../../context/general';
import { useQueryParams } from '../../hooks/queryParams';
import { reducer, generateInitialState } from '../../utils/reducer';
import { download } from '../../utils/download';
import { generateErrorMessages } from '../../utils/error';
import { DownloadIcon } from '../Icons';
import Tooltip from '../Tooltip';
import { Pagination } from '../Pagination';

/**
 * @typedef TSnapshot
 * @property {string} generated_at
 * @property {number} id
 * @property {'final'|'intermediate'} type
 * @property {string} updated_at
 */

/**
 * @typedef TSnapshotsState
 * @property {TSnapshot[]} items
 * @property {number} count
 */

const takeDefault = 30;

export function SnapshotsPage() {
  const { api, notificationActions } = React.useContext(GeneralContext);
  // /** @type {[TSnapshotsState, import('react').Dispatch<import('utils/reducer').TUtilsReducerAction<TSnapshotsState>>]} */
  const [state, dispatchState] = React.useReducer(
    reducer,
    generateInitialState({ items: [], count: 0 })
  );
  const [query] = useQueryParams();

  const take = query.take ? Number(query.take) : takeDefault;
  const skip = query.skip ? Number(query.skip) : 0;

  function fetchSnapshots() {
    dispatchState({ type: 'start' });

    const queryParams = new URLSearchParams();
    if (take) queryParams.set('take', String(take));
    if (skip) queryParams.set('skip', String(skip));
    if (query.search) queryParams.set('search', query.search);

    api(`/admin/snapshot?${queryParams.toString()}`)
      .then((/** @type {TSnapshotsState} */ data) =>
        dispatchState({ type: 'success', data })
      )
      .catch((err) => {
        dispatchState({ type: 'fail', errors: generateErrorMessages(err) });
        notificationActions.addNotification(err);
      });
  }

  function takeRankingSnapshot() {
    api('/admin/snapshot', { method: 'POST' })
      .then((data) => {
        console.log(data);
        notificationActions.addNotification('Snapshot created', {
          timeout: 5000,
        });
        fetchSnapshots();
      })
      .catch((err) => notificationActions.addNotification(err));
  }

  function downloadRanking(item) {
    api(`/admin/snapshot/${item.id}/ranking`, undefined, 'text')
      .then((data) => download(`ranking-${item.generated_at}.csv`, data))
      .catch((err) => notificationActions.addNotification(err));
  }

  function downloadCompetitionState(item) {
    api(`/admin/snapshot/${item.id}/state`, undefined, 'text')
      .then((data) =>
        download(`competition_state-${item.generated_at}.csv`, data)
      )
      .catch((err) => notificationActions.addNotification(err));
  }

  React.useEffect(() => {
    fetchSnapshots();
  }, [take, skip, query.search]);

  return (
    <>
      <button
        type="button"
        className="button button-outline"
        onClick={takeRankingSnapshot}
      >
        Take ranking snapshot
      </button>
      {state.loaded ? (
        <table>
          <thead>
            <tr>
              <th>id</th>
              <th>type</th>
              <th>generated</th>
              <th>actions</th>
            </tr>
          </thead>
          <tbody>
            {state.data.items.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.type}</td>
                <td>{new Date(item.generated_at).toLocaleString()}</td>
                <td>
                  <Tooltip title="Download ranking snapshot.">
                    <button
                      type="button"
                      className="button button-icon"
                      onClick={() => downloadRanking(item)}
                    >
                      <DownloadIcon />
                    </button>
                  </Tooltip>
                  <Tooltip title="Download competition state snapshot.">
                    <button
                      type="button"
                      className="button button-icon"
                      onClick={() => downloadCompetitionState(item)}
                    >
                      <DownloadIcon />
                    </button>
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        'loading...'
      )}
      <Pagination skip={skip} take={take} total={state.data.count} />
    </>
  );
}
