import React from 'react';
import { Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../PrivateRoute';
import { EditGroup } from './EditGroup';
import { GroupList } from './GroupList';

export function GroupPage() {
  const { url } = useRouteMatch();
  return (
    <Switch>
      <PrivateRoute exact path={`${url}/edit`} component={EditGroup} />
      <PrivateRoute exact path={`${url}/edit/:id`} component={EditGroup} />
      <PrivateRoute exact path={`${url}`} component={GroupList} />
      <Redirect to={url} />
    </Switch>
  );
}
