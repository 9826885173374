import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { GeneralContext } from '../../context/general';

import { Header } from '../Header';
import { DashboardPage } from '../DashboardPage';
import { GroupPage } from '../GroupPage';
import { LoginPage } from '../LoginPage';
import { SnapshotsPage } from '../SnapshotsPage';
import { PrivateRoute } from '../PrivateRoute';
import s from './s.module.css';

export function App() {
  const { generalSelectors } = React.useContext(GeneralContext);

  if (!generalSelectors.isUserFetched) {
    return 'Fetching user';
  }

  return (
    <>
      <Header />
      <main className={s.main}>
        <Switch>
          <PrivateRoute exact path="/" component={DashboardPage} />
          <PrivateRoute path="/group" component={GroupPage} />
          <PrivateRoute path="/snapshots" component={SnapshotsPage} />
          <Route path="/login">
            <LoginPage />
          </Route>
          <Redirect to="/" />
        </Switch>
      </main>
    </>
  );
}
