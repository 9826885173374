import React from 'react';
import { nanoid } from 'nanoid';

export function Select({ children, options, id, className, value, ...rest }) {
  const inputId = React.useMemo(() => id ?? nanoid(), [id]);

  // const selectedOption = React.useMemo(() => value && options.find(opt => opt.value === value), [value, options]);

  return (
    <label className={className} htmlFor={inputId}>
      <div className="mb1">{children}</div>
      <select id={inputId} value={value} {...rest}>
        {options.map((opt) => (
          <option {...opt} key={opt.value} />
        ))}
      </select>
      {/* {selectedOption && <div className={s.data}>{selectedOption.children}</div>} */}
    </label>
  );
}
