import React from 'react';
import { nanoid } from 'nanoid';

export function Input({ children, id, className, value, ...rest }) {
  const inputId = React.useMemo(() => id ?? nanoid(), [id]);

  return (
    <>
      <label className={className} htmlFor={inputId}>
        <div className="mb1">{children}</div>
        <input id={inputId} value={value} {...rest} />
      </label>
    </>
  )
}
