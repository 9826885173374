/**
 * @template T
 * @typedef {Object} TUtilsReducerState
 * @property {T} TUtilsReducerState.data
 * @property {boolean} TUtilsReducerState.loading
 * @property {boolean} TUtilsReducerState.loaded
 * @property {string[]} TUtilsReducerState.errors
 */

/**
 * @template T
 * @typedef {{ type: 'start' } | { type: 'success', data: T } | { type: 'fail', errors: string[] }} TUtilsReducerAction
 */

/**
 * @template T
 * @param {TUtilsReducerState<T>} state
 * @param {TUtilsReducerAction<T>} action
 * @returns {TUtilsReducerState<T>}
 */
export function reducer(state, action) {
  switch (action.type) {
    case 'start':
      return { ...state, loading: true };
    case 'success':
      return { ...state, loading: false, loaded: true, data: action.data };
    case 'fail':
      return { ...state, loading: false, loaded: false, errors: action.errors };
    default:
      throw new Error();
  }
}

/**
 * @template T
 * @function TUtilsGenerateInitialState
 * @param {T} data
 * @returns {TUtilsReducerState<T>}
 */
export function generateInitialState(data) {
  return {
    data,
    loading: false,
    loaded: false,
    errors: [],
  };
}
