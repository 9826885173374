import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { GeneralContext } from '../context/general';

/**
 * @param {Object} params
 * @param {Function} params.component
 * @returns {React.ReactElement}
 */
export function PrivateRoute({ component: Component, ...rest }) {
  const { generalSelectors } = React.useContext(GeneralContext);

  if (!generalSelectors.isUserFetched) {
    return 'loading...';
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        generalSelectors.isLogged ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
}
