import * as React from 'react';
import ReactDOM from 'react-dom';

import s from './s.module.css';

const rootNode = document.querySelector('#root');
const modalsNode = document.querySelector('#modals-root');

export const Modal = ({
  children,
  heading,
  // footer,
  closeHandler,
}) => {
  const keyboardHandler = React.useCallback((event) => {
    if (closeHandler && event.key === 'Escape') {
      closeHandler();
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener('keydown', keyboardHandler, false);

    if (rootNode) {
      rootNode.setAttribute('inert', 'true');
      rootNode.setAttribute('aria-hidden', 'true');
    }

    return () => {
      document.removeEventListener('keydown', keyboardHandler, false);

      if (rootNode) {
        rootNode.removeAttribute('inert');
        rootNode.removeAttribute('aria-hidden');
      }
    };
  }, []);

  if (!modalsNode) return null;

  return ReactDOM.createPortal(
    <section className={s.dialog}>
      <div className={s.inner}>
        <h1 className={s.heading}>{heading}</h1>
        <div className={s.content}>{children}</div>
      </div>
    </section>,
    modalsNode
  );
};
