import React from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';

import s from './s.module.css';

const Tooltip = ({ children, title, text }) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [targetRef, setTargetRef] = React.useState(null);
  const [popperRef, setPopperRef] = React.useState(null);
  const [arrowRef, setArrowRef] = React.useState(null);

  const { styles, attributes } = usePopper(targetRef, popperRef, {
    placement: 'top',
    strategy: 'absolute',
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      { name: 'arrow', options: { element: arrowRef } },
      { name: 'preventOverflow', enabled: true },
      // { name: 'flip', enabled: false },
    ],
  });

  return (
    <>
      {React.cloneElement(children, {
        ref: setTargetRef,
        onMouseEnter: () => setShowTooltip(true),
        onMouseLeave: () => setShowTooltip(false),
      })}
      {showTooltip &&
        ReactDOM.createPortal(
          <div
            ref={setPopperRef}
            className={s.popper}
            style={styles.popper}
            {...attributes.popper}
          >
            {title ? <div className={s.title}>{title}</div> : null}
            {text ? <div className={s.text}>{text}</div> : null}
            <div ref={setArrowRef} className={s.arrow} style={styles.arrow} />
          </div>,
          document.querySelector('#tooltip')
        )}
    </>
  );
};

export default Tooltip;
