import React from 'react';
import { NavLink } from 'react-router-dom';

import { GeneralContext } from '../../context/general';
import s from './s.module.css';

export function Header() {
  const { generalSelectors, generalActions } = React.useContext(GeneralContext);

  if (!generalSelectors.isLogged) {
    return null;
  }

  return (
    <header className={s.header}>
      <nav>
        <NavLink
          to="/"
          exact
          className="button button-clear"
          activeClassName={s.activeLink}
        >
          Home
        </NavLink>
        <NavLink
          to="/group"
          className="button button-clear"
          activeClassName={s.activeLink}
        >
          Group
        </NavLink>
        <NavLink
          to="/snapshots"
          className="button button-clear"
          activeClassName={s.activeLink}
        >
          Snapshots
        </NavLink>
        <a
          href={process.env.REACT_APP_DASHBOARD_URL}
          className="button button-clear"
          target="_blank"
          rel="noopener noreferrer"
        >
          Dashboard
          <svg
            viewBox="0 0 9 9"
            style={{
              width: 6,
              height: 6,
              transform: 'translate(0.2em, -0.2em)',
            }}
          >
            <path
              d="M9 0v7H7l-.001-3.586L1.464 8.95.05 7.536 5.585 2H2V0z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </svg>
        </a>
      </nav>
      <div className={s.userWrap}>
        <span className={s.userName}>{generalSelectors?.user?.email}</span>
        <button
          type="button"
          className="button"
          onClick={() => {
            generalActions.setUser(null);
            generalActions.setToken(null);
          }}
        >
          Logout
        </button>
      </div>
    </header>
  );
}
