import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GeneralContext } from '../../../context/general';
import { generateInitialState, reducer } from '../../../utils/reducer';
import { Input } from '../../Input';
import { Select } from '../../Select';
import { CloseIcon } from '../../Icons';
import s from './s.module.css';

export function EditGroup() {
  const { id } = useParams();
  const { api, notificationActions, generalSelectors } = React.useContext(
    GeneralContext
  );
  const history = useHistory();
  const [state, setState] = React.useState({ id: '', name: '', region: '' });
  const [fetchedState, dispatchFetchedState] = React.useReducer(
    reducer,
    generateInitialState()
  );
  const [miners, setMiners] = React.useState(['']);
  const isEdit = !!id;

  const regions = React.useMemo(() => {
    return [
      { value: '', children: 'select region', disabled: true },
      ...Object.keys(generalSelectors.regions).map((regionsResponseKey) => ({
        value: regionsResponseKey,
        children: generalSelectors.regions[regionsResponseKey],
      })),
    ];
  }, [generalSelectors.regions]);

  React.useEffect(() => {
    if (id) {
      dispatchFetchedState({ type: 'start' });
      api(`/admin/group/${id}`)
        .then((response) => {
          dispatchFetchedState({ type: 'success', data: response });
          setState({
            id: response.id,
            name: response.name,
            region: response.region,
          });
          setMiners(response.miners.map((responseMiner) => responseMiner.id));
        })
        .catch((err) => {
          dispatchFetchedState({ type: 'success', errors: err });
          notificationActions.addNotification(err, { timeout: Infinity });
        });
    } else {
      dispatchFetchedState({
        type: 'success',
        data: { id: '', name: '', region: '' },
      });
    }
  }, [id]);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const changeMinerHandler = (e) => {
    const { name, value } = e.target;
    setMiners((prevState) => {
      const nextState = [...prevState];
      nextState[name] = value;
      return nextState;
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const body = JSON.stringify({
      ...state,
      miners: miners
        .filter((minerId) => !!minerId.trim())
        .map((minerId) => ({ id: minerId })),
    });

    const request = isEdit
      ? api(`/admin/group/${id}`, { method: 'PUT', body })
      : api('/admin/group', { method: 'POST', body });

    request
      .then(() => {
        history.push('/group');
      })
      .catch((err) => {
        notificationActions.addNotification(err, { timeout: Infinity });
      });
  };

  if (id && !fetchedState.loaded) {
    return 'loading';
  }

  return (
    <form onSubmit={submitHandler}>
      <div className={s.row}>
        <div className={s.col}>
          <Input
            className="mb2"
            type="text"
            name="id"
            value={state.id}
            onChange={changeHandler}
            required
          >
            id
          </Input>
          <Input
            className="mb2"
            type="text"
            name="name"
            value={state.name}
            onChange={changeHandler}
            required
          >
            name
          </Input>
          <Select
            className="mb2"
            options={regions}
            name="region"
            value={state.region}
            onChange={changeHandler}
            required
          >
            region
          </Select>
        </div>
        <div className={s.col}>
          <ol className="mb2">
            <li style={{ fontWeight: 600 }}>miners</li>
            {miners.map((minerId, idx) => (
              <li key={idx} className="mb1" style={{ display: 'flex' }}>
                <Input
                  type="text"
                  name={idx}
                  value={minerId}
                  onChange={changeMinerHandler}
                  required
                />
                <button
                  type="button"
                  className="button button-icon"
                  style={{ alignSelf: 'flex-end' }}
                  onClick={() =>
                    setMiners((prevMiners) =>
                      prevMiners.filter(
                        (prevMiner, prevMinerIdx) => prevMinerIdx !== idx
                      )
                    )
                  }
                >
                  <CloseIcon style={{ color: 'inherit' }} />
                </button>
              </li>
            ))}
          </ol>
          <button
            type="button"
            className="button button-outline"
            onClick={() => setMiners((prevMiners) => [...prevMiners, ''])}
          >
            Add miner
          </button>
        </div>
      </div>
      <button type="submit" className="button">
        Save
      </button>
    </form>
  );
}
