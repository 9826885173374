import React from 'react';

import { GeneralContext } from '../../context/general';
import { reducer, generateInitialState } from '../../utils/reducer';
import { useQueryParams } from '../../hooks/queryParams';

import { ReasonModal } from '../ReasonModal';
import {
  ApproveIcon,
  ApprovedIcon,
  RejectIcon,
  RejectedIcon,
  BanIcon,
} from '../Icons';
import Tooltip from '../Tooltip';
import { Pagination } from '../Pagination';
import { Select } from '../Select';
import { Input } from '../Input';
import s from './s.module.css';

const takeDefault = 30;

function debounce(func, wait, immediate) {
  let timeout;

  return function executedFunction(...args) {
    const context = this;

    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

export function DashboardPage() {
  const [query, setQuery] = useQueryParams();
  const { api, generalSelectors } = React.useContext(GeneralContext);
  const [state, dispatchState] = React.useReducer(
    reducer,
    generateInitialState({ items: [], count: 0 })
  );
  const take = query.take ? Number(query.take) : takeDefault;
  const skip = query.skip ? Number(query.skip) : 0;

  const regions = React.useMemo(() => {
    return [
      { value: '', children: 'All regions' },
      ...Object.keys(generalSelectors.regions).map((regionsResponseKey) => ({
        value: regionsResponseKey,
        children: generalSelectors.regions[regionsResponseKey],
      })),
    ];
  }, [generalSelectors.regions]);

  React.useEffect(() => {
    dispatchState({ type: 'start' });

    const queryParams = new URLSearchParams();
    if (take) queryParams.set('take', String(take));
    if (skip) queryParams.set('skip', String(skip));
    if (query.region) queryParams.set('region', query.region);
    if (query.search) queryParams.set('search', query.search);

    api(`/admin/registration?${queryParams.toString()}`)
      .then((data) => dispatchState({ type: 'success', data }))
      .catch((err) => dispatchState({ type: 'fail', error: console.log(err) }));
  }, [take, skip, query.region, query.search, query.all]);

  const changeHandler = (item) => {
    dispatchState({
      type: 'success',
      data: {
        ...state.data,
        items: state.data.items.map((dataItem) =>
          dataItem.id === item.id ? item : dataItem
        ),
      },
    });
  };

  const deb = React.useCallback(
    debounce(
      (name, value) => setQuery((q) => ({ ...q, [name]: value, skip: null })),
      400
    ),
    [setQuery]
  );

  return (
    <>
      <div className={s.filters}>
        <Select
          options={regions}
          name="region"
          value={query.region}
          onChange={(e) =>
            setQuery((q) => ({ ...q, region: e.target.value, skip: null }))
          }
        />
        <Input
          style={{ marginLeft: 8, minWidth: 240 }}
          type="search"
          placeholder="Enter the miner ID or nickname"
          name="search"
          defaultValue={query.search}
          onChange={(e) => deb(e.target.name, e.target.value)}
        />
      </div>
      {state.loaded ? (
        <table className={s.table}>
          <thead>
            <tr>
              <th>Miner ID</th>
              <th>Name</th>
              <th>Status</th>
              <th>Info</th>
              <th align="right" style={{ paddingRight: 16 }}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {state.data.count ? (
              state.data.items.map((item) => (
                <TableRow
                  key={item.id}
                  item={item}
                  changeCallback={changeHandler}
                />
              ))
            ) : (
              <tr>
                <td colSpan={5} style={{ padding: 16, textAlign: 'center' }}>
                  No miners found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        'loading...'
      )}
      <Pagination skip={skip} take={take} total={state.data.count} />
    </>
  );
}

function TableRow({ item, changeCallback }) {
  const { api, notificationActions } = React.useContext(GeneralContext);
  const [openModal, setOpenModal] = React.useState(false);
  const [openBanModal, setOpenBanModal] = React.useState(false);

  const approveHandler = () => {
    api(`/admin/registration/${item.id}`, {
      method: 'PUT',
      body: JSON.stringify({ approved: true }),
    })
      .then(changeCallback)
      .catch((err) =>
        notificationActions.addNotification(err, { timeout: Infinity })
      );
  };

  const rejectHandler = () => setOpenModal(true);

  const submitRejectHandler = (reason) => {
    api(`/admin/registration/${item.id}`, {
      method: 'PUT',
      body: JSON.stringify({ approved: false, reason }),
    })
      .then(changeCallback)
      .catch((err) =>
        notificationActions.addNotification(err, { timeout: Infinity })
      );
    setOpenModal(false);
  };

  const banHandler = () => setOpenBanModal(true);

  const submitBanHandler = (reason) => {
    api(`/admin/ban/${item.id}`, {
      method: 'PUT',
      body: JSON.stringify({ banned: true, reason }),
    })
      .then(changeCallback)
      .catch((err) =>
        notificationActions.addNotification(err, { timeout: Infinity })
      );
    setOpenBanModal(false);
  };

  let reason = 'N/A';
  if (item.miner.banned === true) {
    reason = (
      <Tooltip title="Disqualified" text={item.miner?.banned_reason}>
        <div style={{ width: 24, height: 24 }}>
          <BanIcon />
        </div>
      </Tooltip>
    );
  } else if (item.approved) {
    reason = (
      <Tooltip title="Approved">
        <div style={{ width: 24, height: 24 }}>
          <ApprovedIcon />
        </div>
      </Tooltip>
    );
  } else if (item.approved === false) {
    reason = (
      <Tooltip title="Rejected" text={item.reason}>
        <div style={{ width: 24, height: 24 }}>
          <RejectedIcon />
        </div>
      </Tooltip>
    );
  }

  const minerDetailsTable = (
    <table className={s.minerInfoTable}>
      <tbody>
        <tr>
          <th scope="row">region</th>
          <td>{item.region.name}</td>
        </tr>
        <tr>
          <th scope="row">country</th>
          <td>{item.country.name}</td>
        </tr>
        <tr>
          <th scope="row">city</th>
          <td>{item.city}</td>
        </tr>
        <tr>
          <th scope="row">email</th>
          <td>{item.email}</td>
        </tr>
        <tr>
          <th scope="row">registration ip</th>
          <td>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://whatismyipaddress.com/ip/${item.ip}`}
            >
              {item.ip}
            </a>
          </td>
        </tr>
        <tr>
          <th scope="row">node_type</th>
          <td>{item.node_type}</td>
        </tr>
        <tr>
          <th scope="row">node_version</th>
          <td>{item.node_version}</td>
        </tr>
        {item.reason && (
          <tr>
            <th scope="row">reject reason</th>
            <td>{item.reason}</td>
          </tr>
        )}
        {item.miner?.banned_reason && (
          <tr>
            <th scope="row">ban reason</th>
            <td>{item.miner?.banned_reason}</td>
          </tr>
        )}
      </tbody>
    </table>
  );

  return (
    <>
      <tr
        key={item.id}
        style={{
          background: item.miner?.banned
            ? `repeating-linear-gradient(45deg, #fff, #fff 10px, var(--color-tertiary) 10px, var(--color-tertiary) 20px)`
            : '',
        }}
      >
        <td>{item.miner_id}</td>
        <td>{item.name}</td>
        <td>{reason}</td>
        <td>
          <details style={{ display: 'inline-block' }}>
            <Tooltip text={minerDetailsTable}>
              <summary style={{ cursor: 'pointer' }}>Miner Info</summary>
            </Tooltip>
            <div style={{ paddingLeft: 16 }}>{minerDetailsTable}</div>
          </details>
        </td>
        <td align="right">
          <Tooltip title="Approve">
            <button
              type="button"
              className="button button-icon"
              onClick={() => approveHandler()}
              disabled={typeof item.approved === 'boolean'}
            >
              <ApproveIcon />
            </button>
          </Tooltip>
          <Tooltip title="Reject">
            <button
              type="button"
              className="button button-icon"
              onClick={() => rejectHandler()}
              disabled={typeof item.approved === 'boolean'}
            >
              <RejectIcon />
            </button>
          </Tooltip>
          <Tooltip
            title="Disqualify"
            text="Disqualify this miner. You’ll be asked to provide a reason that will be emailed to the miner."
          >
            <button
              type="button"
              className="button button-icon"
              onClick={() => banHandler()}
              disabled={!item.approved || item.miner?.banned}
            >
              <BanIcon />
            </button>
          </Tooltip>
        </td>
      </tr>
      {openModal && (
        <ReasonModal
          title="Please describe reject reason"
          resolve={submitRejectHandler}
          reject={() => setOpenModal(false)}
        />
      )}
      {openBanModal && (
        <ReasonModal
          title="Please describe ban reason"
          resolve={submitBanHandler}
          reject={() => setOpenBanModal(false)}
        />
      )}
    </>
  );
}
