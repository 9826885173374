import React from 'react';

export const ApproveIcon = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, color: '#28a745', ...style }}
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.46446609 12.0502525L9 15.5857864l9.5355339-9.53553387 1.4142136 1.41421356L9 18.4142136l-4.94974747-4.9497475z"
    />
  </svg>
);

export const RejectIcon = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, color: '#ff0000', ...style }}
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.9497475 8.46446609L13.4142136 12l3.5355339 3.5355339-1.4142136 1.4142136L12 13.4142136l-3.53553391 3.5355339-1.41421356-1.4142136L10.5857864 12 7.05025253 8.46446609l1.41421356-1.41421356L12 10.5857864l3.5355339-3.53553387z"
    />
  </svg>
);

export const ApprovedIcon = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, color: '#28a745', ...style }}
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.6316894 4.50420168c.7890871-.67226891 1.9475341-.67226891 2.7366212 0l.7555089.65546219c.3357818.302521.772298.47058823 1.2088143.50420168l.9905561.08403361c1.0241343.08403361 1.8467996.90756302 1.930745 1.93277311l.0839455.99159664c.0335782.45378151.2182581.87394958.5036726 1.21008403l.6547744.75630256c.6715635.7731092.6715635 1.9495798 0 2.7394958l-.6547744.7563025c-.3022036.3361344-.4700944.7731092-.5036726 1.210084l-.0839455.9915967c-.0839454 1.02521-.9066107 1.8487394-1.930745 1.9327731l-.9905561.0840336c-.4533054.0336134-.8730325.2184874-1.2088143.5042017l-.7555089.6386554c-.7890871.6722689-1.9475341.6722689-2.7366212 0l-.75550892-.6386554c-.33578174-.3025211-.772298-.4705883-1.20881427-.5042017l-.99055614-.0840336c-1.02413431-.0840337-1.84679958-.9075631-1.93074501-1.9327731l-.08394544-.9915967c-.03357817-.4537815-.21825813-.8739496-.50367261-1.210084l-.6547744-.7563025c-.67156348-.789916-.67156348-1.9495799 0-2.7394958l.6547744-.75630256c.30220357-.33613445.47009444-.77310924.50367261-1.21008403l.08394544-.99159664c.08394543-1.02521009.9066107-1.8487395 1.93074501-1.93277311l.99055614-.08403361c.45330535-.03361345.87303253-.2184874 1.20881427-.50420168zm4.0085853 4.76292025l-3.8458839 3.84992367-1.76159507-1.7634454-.9825432.9835753 2.74413827 2.7470207 4.8284271-4.833499-.9825432-.98357527z"
    />
  </svg>
);

export const RejectedIcon = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, color: '#ff0000', ...style }}
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.4963274 10.6386555l-.6547744-.75630256c-.2854145-.33613445-.4700944-.75630252-.5036726-1.21008403l-.0839455-.99159664c-.0839454-1.02521009-.9066107-1.8487395-1.930745-1.93277311l-.9905561-.08403361c-.4365163-.03361345-.8730325-.20168068-1.2088143-.50420168l-.7555089-.65546219c-.7890871-.67226891-1.9475341-.67226891-2.7366212 0l-.75550892.65546219c-.33578174.28571428-.75550892.47058823-1.20881427.50420168l-.99055614.08403361c-1.02413431.08403361-1.84679958.90756302-1.93074501 1.93277311l-.08394544.99159664c-.03357817.43697479-.20146904.87394958-.50367261 1.21008403l-.6547744.75630256c-.67156348.7899159-.67156348 1.9495798 0 2.7394958l.6547744.7563025c.28541448.3361344.47009444.7563025.50367261 1.210084l.08394544.9915967c.08394543 1.02521.9066107 1.8487394 1.93074501 1.9327731l.99055614.0840336c.43651627.0336134.87303253.2016806 1.20881427.5042017l.75550892.6386554c.7890871.6722689 1.9475341.6722689 2.7366212 0l.7555089-.6386554c.3357818-.2857143.7555089-.4705883 1.2088143-.5042017l.9905561-.0840336c1.0241343-.0840337 1.8467996-.9075631 1.930745-1.9327731l.0839455-.9915967c.0335782-.4369748.201469-.8739496.5036726-1.210084l.6547744-.7563025c.6715635-.789916.6715635-1.9663866 0-2.7394958zM15 9.85714286L12.8571429 12 15 14.1428571 14.1428571 15 12 12.8571429 9.85714286 15 9 14.1428571 11.1428571 12 9 9.85714286 9.85714286 9 12 11.1428571 14.1428571 9 15 9.85714286z"
    />
  </svg>
);

export const CloseIcon = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, color: '#2d2926', ...style }}
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.9497475 8.46446609L13.4142136 12l3.5355339 3.5355339-1.4142136 1.4142136L12 13.4142136l-3.53553391 3.5355339-1.41421356-1.4142136L10.5857864 12 7.05025253 8.46446609l1.41421356-1.41421356L12 10.5857864l3.5355339-3.53553387z"
    />
  </svg>
);

export const BanIcon = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 48 48"
    style={{ width: 24, height: 24, ...style }}
    {...rest}
  >
    <path
      fill="#d50000"
      d="M9.63 35.562L35.651 9.54l2.829 2.829L12.459 38.39z"
    />
    <path
      fill="#d50000"
      d="M24 4C13 4 4 13 4 24s9 20 20 20 20-9 20-20S35 4 24 4zm0 36c-8.8 0-16-7.2-16-16S15.2 8 24 8s16 7.2 16 16-7.2 16-16 16z"
    />
  </svg>
);

export const DownloadIcon = ({ style, ...rest }) => (
  <svg
    fill="none"
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...rest}
  >
    <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" fill="#000" />
  </svg>
);
