import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import s from './s.module.css';

/**
 * @param {number} current
 * @param {number} last
 * @param {number} [delta=2]
 */
function pagination(current, last, delta = 2) {
  const left = current - delta;
  const right = current + delta + 1;
  const range = [];
  const rangeWithDots = [];
  let l;

  range.push(1);
  for (let i = current - delta; i <= current + delta; i++) {
    if (i >= left && i < right && i < last && i > 1) {
      range.push(i);
    }
  }
  range.push(last);

  range.forEach((i) => {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push(null);
      }
    }
    rangeWithDots.push(i);
    l = i;
  });

  return rangeWithDots;
}

/**
 * @param {number} skip
 */
function generateLinkUrl(skip) {
  return (location) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('skip', skip);
    return `${location.pathname}?${queryParams.toString()}`;
  };
}

export function Pagination({ skip, take, total }) {
  const currentPage = skip / take + 1;
  const pages = Math.ceil(total / take);
  const paginationList = pagination(currentPage, pages);

  if (pages <= 1) {
    return null;
  }

  return (
    <ol className={s.pagination}>
      <li>
        <NavLink
          to={generateLinkUrl(skip - take)}
          className={s.arrowLink}
          disabled={currentPage <= 1}
        >
          <svg viewBox="0 0 7 10">
            <path
              d="M.05 5L5 9.95l1.414-1.414L2.879 5l3.535-3.536L5 .05z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </svg>
        </NavLink>
      </li>
      {paginationList.map((page, idx) => (
        <li key={idx}>
          <NavLink
            to={generateLinkUrl((page - 1) * take)}
            className={cn(s.pageLink, {
              [s.pageLinkActive]: currentPage === page,
            })}
            disabled={!page}
          >
            {page || '...'}
          </NavLink>
        </li>
      ))}
      <li>
        <NavLink
          to={generateLinkUrl(skip + take)}
          className={s.arrowLink}
          disabled={currentPage >= pages}
        >
          <svg viewBox="0 0 7 10">
            <path
              d="M6.414 5l-4.95 4.95L.05 8.536 3.586 5 .05 1.464 1.464.05z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </svg>
        </NavLink>
      </li>
    </ol>
  );
}
